import { Routes, Route } from 'react-router-dom';
import { MainPage } from './pages/MainPage.jsx';
import { Header } from './pages/Header.jsx';
import { NotFoundPage } from './pages/NotFoundPage.jsx';
import { PricePage } from './pages/PricePage.jsx';
import { ContactsPage } from './pages/ContactsPage.jsx';

function App() {
  return (
    <div className="App">
      <Routes>
          <Route path="/" element={<Header />}>
            <Route index element={<MainPage />} />
            <Route path="price" element={<PricePage />} />
            <Route path="contacts" element={<ContactsPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
    </div>
  );
}

export default App;
