import '../style/main.css';

const MainPage = () => {
    return (
        <>
            <div className='main-page'>
                <div className="main-page-bg" style={{backgroundImage: `url("/images/back/bg.png")`}}>
                    <h1 className='main-page-h1'>Мы предоставляем услуги по уничтожению клопов и других насекомых в Москве и Московской области</h1>
                    <div className="main-page-container">
                        <div className="content">
                            <img src="/images/main/price.png" alt="price" />
                            <h3 className='content-h3'>Честные цены по городу и области</h3>
                        </div>
                        <div className="content">
                            <img src="/images/main/watch.png" alt="price" />
                            <h3 className='content-h3'>Полная обработка за 45 минут</h3>
                        </div>
                        <div className="content">
                            <img src="/images/main/diplom.png" alt="price" />
                            <h3 className='content-h3'>Гарантия и качество услуг</h3>
                        </div>
                    </div>
                </div>
                <div className='main-page-text-underhead'>
                    <div className='some-text'>
                        Наша организация специализируется на профессиональной дезинфекции от тараканов. Мы используем только безопасные и эффективные средства, которые гарантируют полное уничтожение насекомых.
                        Наши специалисты обладают богатым опытом и используют современное оборудование, что позволяет нам гарантировать высокое качество услуг.
                        <h4>У нас самые честные цены по уничтожение вредителей!</h4>
                    </div>
                </div>
                <h1 className='main-page-our-advantages'>Наши преимущества</h1>
                <div className='background-second' style={{backgroundImage: `url("/images/back/bg2.jpg")`}}>
                    <div className='main-page-base-upper-container'>
                        <div className='main-page-upper-container'>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/firstIcon.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>Безопасные препараты</h5>
                                </div>
                            </div>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/secondIcon.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>Конфиденциально</h5>
                                </div>
                            </div>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/thirdIcon.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>Работа профессионалов</h5>
                                </div>
                            </div>
                        </div>
                        <div className='main-page-upper-container'>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/rat.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>Мы боремся со всеми видами вредителей</h5>
                                </div>
                            </div>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/build.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>Работаем с любыми объектами</h5>
                                </div>
                            </div>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/truck.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>Приедем за 2 часа</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className='main-page-our-advantages'>Проводим дезинсекцию в Москве и Московской области</h1>
                <div className='main-page-text-underhead'>
                    <div className='some-text'>
                        <h4>Вредители - это не только неприятная проблема, но и угроза вашему здоровью, комфорту и имуществу.</h4>
                        Наша служба санитарной обработки помещений СЭС предлагает профессиональные услуги в Москве и Московской области. Мы занимаемся дезинфекцией, дезинсекцией и дератизацией помещений любой сложности: от квартир и офисов до промышленных предприятий, территорий и участков.

Наша команда состоит из опытных специалистов, которые используют современное оборудование и безопасные для человека и животных средства.
Мы проводим комплексную обработку помещений, территорий и участков от насекомых и грызунов, неприятного запаха, бактерий и вирусов, в том числе возбудителей опасных инфекционных заболеваний.

Мы гарантируем высокое качество наших услуг и предоставляем подробный отчет о проделанной работе.
Организация «Московская областная дезинфекция» всегда готова ответить на ваши вопросы и предоставить консультацию по вопросам санитарной обработки.

Обратившись к нам, вы можете быть уверены в безопасности своего здоровья и окружающей среды. Не откладывайте заботу о своем здоровье на потом – свяжитесь с нами прямо сейчас и получите качественную санитарную обработку помещения или участка!
                    </div>
                </div>
                <h1 className='main-page-our-advantages'>Санитарная обработка на любых объектах</h1>
                <div className='background-second' style={{backgroundImage: `url("/images/back/bg3.png")`}}>
                    <div className='main-page-base-upper-container'>
                        <div className='main-page-upper-container'>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/house.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>Квартиры, дома, коттеджи, дачи и участки</h5>
                                </div>
                            </div>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/bigHouse.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>ТСЖ, ЖСК и Управляющие Компании</h5>
                                </div>
                            </div>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/rinok.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>Столовые, бары, кафе, рестораны</h5>
                                </div>
                            </div>
                        </div>
                        <div className='main-page-upper-container'>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/university.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>Учебные заведения и детсады</h5>
                                </div>
                            </div>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/zavod.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>Заводы, склады, ангары</h5>
                                </div>
                            </div>
                            <div className='upper-container'>
                                <div className='upper-container-image'>
                                    <img src="/images/mainPageIcons/office.png" alt="firstIcon" className='upper-container-image' />
                                </div>
                                <div>
                                    <h5 className='upper-container-first-text'>Бизнес центры, офисы, банки</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className='main-page-our-advantages'>Мы предлагаем профессиональную дезинфекцию от всех видов вредителей</h1>
                <div className='main-page-text-underhead'>
                    <div className='some-text'>
                        <ul>
                            <li>Тараканы: Переносят инфекции, портят продукты и имущество, вызывают стресс и фобии.</li>
                            <li>Клопы: Питаются кровью человека, оставляют укусы, вызывают раздражение кожи, могут стать причиной бессонницы.</li>
                            <li>Грызуны: Разрушают стены, провода, мебель, портят продукты, переносят опасные заболевания.</li>
                            <li>Муравьи: Могут повредить продукты питания, гнездятся в труднодоступных местах, переносят бактерии.</li>
                            <li>Других насекомых: Мошки, комары, мухи, моль - мы поможем вам избавиться от них!</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export {MainPage};