import '../style/contacts.css';
import { FaTelegram, FaPhone  } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdMail  } from "react-icons/io";


const ContactsPage = () => {
    return (
        <>
            <div className="contacts-main-container" style={{backgroundImage: `url("/images/contacts/chel.png")`}}>
                <div className='contacts-container'>
                    <ul>
                        <li><FaPhone className='contacts-icons' /> Телефон: <a href="tel:+74952118998">+7 (495) 211-89-98</a></li>
                        <li><IoMdMail className='contacts-icons' /> Email: <a href="mailto:mos-obl-dez@yandex.ru">mos-obl-dez@yandex.ru</a></li>
                        <li><IoLogoWhatsapp className='contacts-icons' /> WhatsApp: <a href="https://wa.me/+74952118998">+7 (495) 211-89-98</a></li>
                        <li><FaTelegram className='contacts-icons' /> Telegram: <a href="https://t.me/+74952118998">+7 (495) 211-89-98</a></li>
                    </ul>
                </div>
            </div>
            
        </>
    )
}

export {ContactsPage};