import '../style/price.css';

import { FaMoneyBill } from "react-icons/fa";

const PricePage = () => {
    return (
        <>
            <div className="price-page">
                <h1 className='price-h1'>Цены на наши услуги</h1>
                <div className='main-container'>
                    <div className='first-container'>
                        <img src="/images/price/one.jpg" alt="firstImg" className='price-images' />
                        <ul>
                            <li><h2>ОБРАБОТКА ДЕЗИНФЕКЦИЯ КВАРТИР ОТ КЛОПОВ И ТАРАКАНОВ</h2></li>
                            <li><FaMoneyBill className='price-icons' />Однокомнатная квартира 6000 руб.</li>
                            <li><FaMoneyBill className='price-icons' />Двухкомнатная квартира 7000 руб.</li>
                            <li><FaMoneyBill className='price-icons' />Трёхкомнатная квартира 8000 руб.</li>
                            <li><FaMoneyBill className='price-icons' />Четырёхкомнатная квартира и более 9000 руб.</li>
                            <li><FaMoneyBill className='price-icons' />Обработка места общего пользования 3500 руб.</li>
                            <li><FaMoneyBill className='price-icons' />Комплексная обработка холодным туманом +3000 руб.</li>
                            <li><h2>ДЕЗИНФЕКЦИЯ И ОБРАБОТКА КВАРТИРЫ ПОСЛЕ СМЕРТИ</h2></li>
                            <li className='death-text'>При гибели человека в квартире через несколько дней появляется не только трупный запах, но и начинают развиваться патогенные микроорганизмы, которые являются возбудителями опасных заболеваний.</li>
                            <li><FaMoneyBill className='price-icons' />Однокомнатная квартира 9000 руб.</li>
                            <li><FaMoneyBill className='price-icons' />Двухкомнатная квартира 10000 руб.</li>
                            <li><FaMoneyBill className='price-icons' />Трёхкомнатная квартира 11000 руб.</li>
                            <li><FaMoneyBill className='price-icons' />Четырёхкомнатная квартира и более 12000 руб.</li>
                        </ul>
                    </div>
                    <div className='second-container'>
                        <ul>
                            <li><h2>ОБРАБОТКА РАСТЕНИЙ</h2></li>
                            <li><FaMoneyBill className='price-icons' />Обработка деревьев 1600 руб./1 дерево.</li>
                            <li><FaMoneyBill className='price-icons' />Обработка от борщевика 1200 руб./100 кв.м.</li>
                        </ul>
                        <img src="/images/price/two.jpg" alt="secImg" className='price-images' />
                    </div>
                    <div className='third-container'>
                        <img src="/images/price/three.jpg" alt="firstImg" className='price-images' />
                        <ul>
                            <li><h2>ДРУГИЕ УСЛУГИ</h2></li>
                            <li><FaMoneyBill className='price-icons' />Обработка от кротов 1200 руб./100 кв.м</li>
                            <li><FaMoneyBill className='price-icons' />Частный дом и юридические лица 50 руб./кв.м</li>
                            <li><FaMoneyBill className='price-icons' />Места общего пользования(МОП) 3500 руб.</li>
                            <li><FaMoneyBill className='price-icons' />Бытовка 3000 руб.</li>
                            <li><FaMoneyBill className='price-icons-gnezdo' />Уничтожение одного гнезда (осиное, шмели или шерни) от 5000 руб.</li>
                        </ul>
                    </div>
                    <div className='third-container'>
                        <ul>
                            <li><h2>Цены для юридических лиц</h2></li>
                            <li><FaMoneyBill className='price-icons' />Обработка помещения для юридических лиц 50руб./кв.м</li>
                            <li><FaMoneyBill className='price-icons' />Другой объем работы обгаваривается отдельно</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export {PricePage};