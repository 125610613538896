import { Link } from "react-router-dom";

const NotFoundPage = () => {
    return (
        <>
            <h1>Страница не найдена</h1>
            <Link to= "/">Перейти на главную страницу</Link>
        </>
    )
}

export {NotFoundPage};